body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #34004a; /* Cor de fundo para todo o corpo */
}

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh; /* Garante que o conteúdo ocupe a altura total da viewport */
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #34004a; /* Fundo ao redor do banner */
  padding: 20px 0;
}

.banner {
  max-width: 100%;
  height: auto;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #34004a; /* Fundo ao redor do conteúdo */
}

.content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px; /* Limita a largura do conteúdo */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-container {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
}

.legenda {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: #555;
}

.input, .select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.button-group {
  display: flex;
}

.button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f9f9f9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
}

.button.selected {
  background: #34004a;
  color: #fff;
}

.button:not(:last-child) {
  margin-right: 10px;
}

.button-image {
  max-width: 50px;
  max-height: 30px;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.switch {
  width: 40px;
  height: 20px;
  position: relative;
  background: #ccc;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  outline: none;
  transition: background 0.3s;
}

.switch:checked {
  background: #34004a;
}

.switch:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch:checked:before {
  transform: translateX(20px);
}

.result-container {
  margin-top: 20px;
}

.label2 {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.footer {
  width: 100%;
  background-color: #34004a;
  color: #fff;
  padding: 20px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.footer-column h2 {
  margin-top: 0;
}

.footer-column p {
  margin: 5px 0;
}
